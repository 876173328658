
import { Component, Inject, Vue } from "vue-property-decorator";
import SnowBoxList from "@/components/boxes/SnowBoxList.vue";
import { SnowBoxesQuery } from "@/graphql/queries";
import { ISnowBox } from "@/interfaces/snow-box.interface";
import SnowBoxSlideList from "@/components/boxes/SnowBoxSlideList.vue";
import { IS_MOBILE_SYMBOL } from "@/constants";

@Component({
  components: {
    SnowBoxList,
    SnowBoxSlideList,
  },
  apollo: {
    snowBoxes: {
      update(data) {
        return data.snowBoxes || null;
      },
      query: SnowBoxesQuery,
    },
  },
})
export default class SnowBoxes extends Vue {
  @Inject(IS_MOBILE_SYMBOL) isMobile!: boolean;

  private snowBoxes: ISnowBox[] | null = null;
  private eventEndTime = new Date(2025, 1, 28, 0);
  private eventEnded = this.eventEndTime.getTime() < new Date().getTime();
}
