import { render, staticRenderFns } from "./Balance.vue?vue&type=template&id=841948d0&scoped=true&"
import script from "./Balance.vue?vue&type=script&lang=ts&"
export * from "./Balance.vue?vue&type=script&lang=ts&"
import style0 from "./Balance.vue?vue&type=style&index=0&id=841948d0&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "841948d0",
  null
  
)

export default component.exports