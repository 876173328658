var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-app',{class:{
    loading: _vm.$apollo.queries.user.loading,
    'theme--old': _vm.$store.state.theme === 'old',
    'theme--new': _vm.$store.state.theme === 'new',
    'platform--macos': _vm.platform.macos,
    'platform--ios': _vm.platform.ios,
  },attrs:{"id":"app"}},[(!_vm.isMobile)?_c('snow-fall'):_vm._e(),_vm._m(0),(!_vm.$apollo.queries.user.loading)?[_c('drawer'),_c('firebase-messaging'),(!_vm.isMobile)?_c('drawer-right'):_vm._e(),(_vm.isMobile)?_c('mobile-bottom-app-bar'):_vm._e(),(_vm.isMobile)?_c('mobile-app-chat'):_vm._e(),_vm._m(1),_c('deposit'),_c('app-notifications'),_c('v-main',[_c('router-view')],1),_c('base-footer')]:_c('div',{staticClass:"window-fit position-relative"},[(!_vm.serverConnectionError)?_vm._m(2):_vm._m(3)],1)],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('theme-toggle',{staticStyle:{"display":"none"}})
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('authorization')
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-progress-circular',{staticClass:"absolute-center position-absolute",attrs:{"size":130,"width":10,"color":"primary","indeterminate":""}})
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"position-absolute absolute-center flex-column d-flex align-center justify-center"},[_c('div',{staticClass:"text-center text-font-primary text-h5 font-weight-light"},[_vm._v(" Технический перерыв ")])])
}]

export { render, staticRenderFns }